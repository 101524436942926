import { Divider, Grid, SxProps, Typography, useTheme } from '@mui/material';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { AppHeader } from '../components/AppHeader';
import { TknPageContainer } from '../components/TknPageContainer';
import { TknSvgIcon } from '../components/TknSvgIcon';
import { useTranslation } from 'next-i18next';
import { TknButton } from '../components/TknButton';
import { useRouter } from 'next/router';
import { getHomepagePath } from '../utils/tkn-user-util';
import { calculateiOSInset } from '../utils/tkn-util';
import useCurrentUser from '../hooks/useCurrentUser';

interface Page404Style {
  divider: SxProps;
}

const style: Page404Style = {
  divider: {
    bgcolor: 'global.white',
    opacity: 0.1,
    mt: 1,
    mb: 6.25,
  },
};

const Page404 = () => {
  const theme = useTheme();
  const { t } = useTranslation('error');
  const router = useRouter();
  const user = useCurrentUser();

  return (
    <TknPageContainer
      textColor={theme.palette.global.white}
      background={theme.palette.global.radialGreenBlueGradient}
    >
      <Grid container direction="column" gap={5}>
        <Grid item marginBottom={calculateiOSInset(96)}>
          <AppHeader
            backgroundColor="transparent"
            leftChild={
              <TknSvgIcon
                src="/icons/token-logo-white.svg"
                alt="Token Events name logo"
                height={16}
                width={74}
              />
            }
          />
        </Grid>
        <Grid item>
          <Typography variant="h3" marginBottom={2} id="title">
            404
          </Typography>
          <Typography variant="bodyRegular" id="description">
            {t('error', { context: '404' })}
          </Typography>
        </Grid>
        <Divider sx={style.divider} />
        <Grid item marginBottom={3} width={'90%'} alignSelf="center">
          <TknButton
            label={t('takeMeHome')}
            variant="outlined"
            styleVariant="white"
            size="large"
            onClick={() => {
              router.replace(getHomepagePath(user?.role));
            }}
          />
        </Grid>
      </Grid>
    </TknPageContainer>
  );
};

export const getStaticProps = async ({ locale }: { locale: string }) => ({
  props: {
    ...(await serverSideTranslations(locale, ['error'])),
  },
});
export default Page404;
